export default function script_lazyload() {
  $(function () {
    function open() {
      $('.lazyload').lazyload({
        effect: "fadeIn",
        threshold: 100,
        effect_speed: 1000
      });
    }

    $(window).on('load', function () {
      open();
    });
  });
}
