export default function script_burgerMenu() {
	jQuery(function ($) {
		const burgerMenu = $('#burgerMenu'),    //初期のメニュー要素を変数に入れる
			menuSouce = burgerMenu.html(),   //メニュー項目（html）を変数に入れる
			switchPoint = 1025,                //PCブレイクポイント
			slideSpeed = 300,
			fadeSpeed = 500;

		function menuSet() {
			if (window.innerWidth < switchPoint) {   //モバイル、タブレットの場合
				if (!($('#burgerMenuWrap').length)) {  //ハンバーガーメニューが追加されていない場合
					$('body').prepend('<div id="menuOverlay"></div><ul class="burgerBtn" id="switchBtn"><li></li><li></li><li></li></ul><div id="burgerMenuWrap"></div>');  //ハンバーガーメニューを追加
					$('#burgerMenuWrap').append(menuSouce);   //ハンバーガーメニューにメニュー項目（html）をセット

					const menuOverlay = $('#menuOverlay'),                 //オーバーレイ要素を変数に入れる
						switchBtn = $('#switchBtn'),                   //ハンバーガーボタン要素を変数に入れる
						btnRight = parseInt(switchBtn.css('right')),  //ハンバーガーボタン要素のrightプロパティを変数に入れる
						menuWrap = $('#burgerMenuWrap'),              //モバイル用メニュー全体
						menuWidth = menuWrap.outerWidth();             //モバイル用メニューの横幅

					function menuOpen() {   //モバイル用メニューが開く関数を定義
						$.each(switchBtn.find('li'), function (i) {      //ハンバーガーメニューが×になる動作
							if (i == 0) {      //1本目の動作（傾く）
								$(this).addClass('rotateL45');
							}
							if (i == 1) {      //2本目の動作（非表示）
								$(this).css({ opacity: '0' });
							}
							if (i == 2) {      //3本目の動作（傾く）
								$(this).addClass('rotateR45');
							}
						});
						switchBtn.addClass('btnClose').css({ position: 'fixed' }).stop().animate({ right: menuWidth + btnRight }, slideSpeed);   //ハンバーガーメニュー全体を開く
						menuOverlay.css({ display: 'block', opacity: '0' }).stop().animate({ opacity: '1' }, fadeSpeed);                           //オーバーレイ要素を表示
						menuWrap.stop().animate({ right: '0' }, slideSpeed);                                                                  //右に隠しておいたハンバーガーメニュー全体を左に移動する（表示する）
						$('body').css({ position: 'fixed' });                                                                                //body要素にfixedを追加
					}

					function menuClose() {   //モバイル用メニューが閉じる関数を定義
						$.each(switchBtn.find('li'), function (i) {      //ハンバーガーメニューが3本線になる動作
							if (i == 0) {      //1本目の動作（水平にする）
								$(this).removeClass('rotateL45');
							}
							if (i == 1) {      //2本目の動作（非表示を表示にして、水平にする）
								$(this).css({ opacity: '1' });
							}
							if (i == 2) {      //3本目の動作（水平にする）
								$(this).removeClass('rotateR45');
							}
						});
						switchBtn.removeClass('btnClose').removeAttr('style');                //ハンバーガーボタン要素からstyleを削除
						menuOverlay.stop().animate({ opacity: '0' }, fadeSpeed, function () {      //オーバーレイ要素を非表示にして
							menuOverlay.removeAttr('style');                                  //オーバーレイ要素からstyleを削除
						});
						menuWrap.stop().animate({ right: '-' + menuWidth + 'px' }, slideSpeed);  //モバイル用メニュー全体を右に移動する（隠す）
						$('body').removeAttr('style');                                      //ハンバーガーボタンクリック時にbodyに追加したstyleを削除する
					}

					switchBtn.on('click', function () {      //ハンバーガーボタンをクリック
						if ($(this).hasClass('btnClose')) {
							menuClose();   //モバイル用メニューが閉じる関数実行
						} else {
							menuOpen();    //モバイル用メニューが開く関数実行
						}
					});

					menuOverlay.on('click', function () {      //オーバーレイをクリック
						menuClose();
					});
				}
			} else {
				$('#menuOverlay,#switchBtn,#burgerMenuWrap').remove();
				$('body').removeAttr('style');
			}
		}

		$(window).on('resize', function () {
			menuSet();
		});

		menuSet();

	});
}
