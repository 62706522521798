export default function script_fadein() {
  $(function () {
    function open() {
      var fadeinImg = $('.fadeinImg'),
        fadeSpeed = 1500;

      fadeinImg.css({ visibility: 'visible', opacity: '0' }).stop().animate({ opacity: '1' }, fadeSpeed);
    }

    $(window).on('load', function () {
      open();
    });
  });
}
