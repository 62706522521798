export default function script_dropmenu() {
  jQuery(function ($) {
    const switchPoint = 1025;  //PCブレイクポイント

    function openDropmenu() {
      if (window.innerWidth >= switchPoint) {   //PCの場合
        $('#globalMenu > div > ul > li').hover(function () {
          let childPanel = $(this).children('.Dropmenu__body');
          childPanel.each(function () {
            childPanel.css({ display: 'block', opacity: '0' }).stop().animate({ opacity: '1' }, 500, 'swing');
          });
        }, function () {
          let childPanel = $(this).children('.Dropmenu__body');
          childPanel.css({ display: 'none' });
        });
      }
    }

    openDropmenu();   //openDropmenu関数の実行

    $(window).on('resize', function () {   //リサイズした時にopenDropmenu関数の実行
      openDropmenu();
    })

  });
}
