//モジュールのインポート
import 'jquery.easing';        //jquery.easing読み込み
import './modules/jquery.lazyload.min';  //jquery.lazyload.min読み込み
import script_loadingSplash from './modules/script_loadingSplash';    //ローディング（スプラッシュ）読み込み
import script_burgerMenu from './modules/script_burgerMenu';          //ハンバーガーメニュー読み込み
import script_dropmenu from './modules/script_dropmenu';              //ドロップメニュー読み込み
import script_fadein from './modules/script_fadein';                  //フェードイン読み込み
import script_telNonPc from './modules/script_telNonPc';              //PC電話発信なし読み込み
import script_lazyload from './modules/script_lazyload';              //lazyload読み込み

//モジュール先にある関数実行
script_loadingSplash();   //ローディング（スプラッシュ）スクリプト実行
script_burgerMenu();      //ハンバーガーメニュースクリプト実行
script_dropmenu();        //ドロップメニュースクリプト実行
script_fadein();          //フェードインスクリプト実行
script_telNonPc();        //PC電話発信なしスクリプト実行
script_lazyload();        //lazyloadスクリプト実行